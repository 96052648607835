import { Subtitle } from "../atoms/Subtitle";
import { PaymentOption } from "../molecules/PaymentOption";

export function PaymentMethods({ title }) {
  return (
    <div className="lg:col-span-2 xl:col-span-1">
      <Subtitle title={title} />

      <div className="mb-2">
        <PaymentOption selected={true} type="onlineBanking" />
      </div>

      <PaymentOption disabled={true} type="creditCard" />
    </div>
  );
}
