import { OverflowContainer } from "../OverflowContainer";
import { PaymentStatusMessage } from "../molecules/PaymentStatusMessage";

export function SuccessfulScreen({ title }) {
  return (
    <OverflowContainer>
      <PaymentStatusMessage title={title} variant="success" />
    </OverflowContainer>
  );
}
