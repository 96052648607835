import { useEffect } from "react";
import { useContext, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { AddBankButton } from "../components/atoms/AddBankButton";
import { Avatar } from "../components/atoms/Avatar";

import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { OverflowContainer } from "../components/OverflowContainer";
import { RetrievalPaymentContext } from "../context/RetrievalPaymentContext";

import { UserContext } from "../context/UserContext";
import { capitalizeString } from "../utils/capitalizeString";

export function SignUpCompleted() {
  const [isAccountBeingCreated, setIsAccountBeingCreated] = useState(true);

  const { paymentDetails } = useContext(RetrievalPaymentContext);

  const { user: userInformation } = useContext(UserContext);

  const userFirstName = capitalizeString(userInformation.name.split(" ")[0]);
  
  useEffect(() => {
    setTimeout(() => {
      setIsAccountBeingCreated(false);
    }, 2000)
  });

  return (
    <div className="w-full h-full">
      <Header title={"Manage your finances"} />

      <div className="w-full container px-6 lg:px-6 xl:px-0 pb-6 mx-auto mt-6">
        <Breadcrumbs title={"Manage your finances"} />
        {isAccountBeingCreated ? (
          <OverflowContainer>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="3"
              animationDuration="0.75"
              width="48"
              visible={true}
            />

            <h1 className="mt-4 leading-6 font-bold text-lg">
              Creating your Wealth.on account
            </h1>
          </OverflowContainer>
        ) : (
          <div className="w-full max-w-2xl">
            <Avatar letterIcon={"W"} />
            <h1 className="text-2xl font-bold mt-6 mb-4 text-left">
              Welcome, {userFirstName}!
            </h1>

            <span className="block w-full border-t border-neutral-200 my-6"></span>

            <div>
              <h2 className="text-base text-neutral-600 font-semibold mb-4">Investments</h2>
                <div className="rounded-md border border-neutral-200 py-5 px-4 mb-6">
                  <span className="text-sm font-semibold text-neutral-800 mr-1">You have no investments yet</span>
                </div>
            </div>

            <div className="mb-4">
              <h2 className="text-base text-neutral-600 font-semibold mb-4">Linked accounts</h2>
              <h3 className="text-neutral-600 leading-5 mb-4">Every time you add money to Wealth.on, it will transfer from your primary account</h3>

              { paymentDetails.map(account => (
                <div key={Math.random()} className="flex justify-between items-center rounded-md border border-neutral-200 p-4 mb-2">
                  <img className="rounded-md" width="32" src={`https://paywithmybank.com/start/assets/institutions/${account.bankId}.png`} alt="" />
                  <div className="flex flex-col ml-3 mr-auto">
                    <strong className="text-sm text-neutral-800 leading-4 font-semibold">{account.bankName}</strong>
                    <span className="text-sm text-neutral-800 leading-5">{account.accountType} *{account.accountNumber}</span>
                  </div>
                  <span className="text-sm text-neutral-800 leading-4 font-semibold">
                    {new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(account.accountBalance)}
                  </span>
                </div>
              ))}
            </div>

            <AddBankButton paymentType={"Retrieval"} />

            <Link to={"/"} className={"w-full"}>
              <button
                type="button"
                className="w-full mt-6 inline-flex justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
              >
                Back to use cases
              </button>
            </Link>
          </div>
            
        )}
      </div>
    </div>
  );
}
