import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../components/Header";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { TrustlyFlow } from "../components/TrustlyFlow";
import { Avatar } from "../components/atoms/Avatar";
import { SplashScreen } from "../components/organisms/SplashScreen";

import { queryStringToJson } from "../utils/queryStringToJson";

import trustlyLogo from "../assets/trustly-logo-green.svg";

export function SignUp() {
  const [isLightBoxRendered, setIsLightBoxRendered] = useState(false);
  const [isLoadingDemo, setIsLoadingDemo] = useState(true);
  const navigate = useNavigate();


  function handleLightBoxClosing() {
    window.Trustly.addPanelListener((command, obj) => {
      if (obj?.type === "new_location" && obj.data.indexOf("#return") === 0) {
        obj.preventDefault();
        setIsLightBoxRendered(false)

        const { transactionId } = queryStringToJson(obj.data);

        navigate('/signup/review', { state : { transactionId }});
      }

      if (obj?.type === "new_location" && obj.data.indexOf("#cancel") === 0) {
        obj.preventDefault();
        setIsLightBoxRendered(false);
      }
    });
  }

  function handleSignUpClick() {
    setIsLightBoxRendered(true);
    handleLightBoxClosing();
  }

  useEffect(() => {
    setTimeout(() => setIsLoadingDemo(false), 2000)
  }, []);

  return (
    <div className={`w-full h-full ${isLoadingDemo ? "bg-green-900" : ""}`}>
      <Header title="Manage your finances" />

      { isLoadingDemo ? (
        <SplashScreen merchantName={"Wealth.on"} />
      ) : (
        <div className="w-full container px-6 lg:px-6 xl:px-0 pb-6 mx-auto mt-6">
          <Breadcrumbs title="Manage your finances" />
          <div className="max-w-sm w-full mx-auto md:absolute md:top-1/2 md:-translate-y-1/2 md:left-1/2 md:-translate-x-1/2">
            <Avatar letterIcon="W" />
            <h1 className="text-2xl font-bold mt-6 mb-4 text-left">Wealth.on helps you save & invest</h1>
            <h2 className="text-neutral-600 leading-5 mb-10">Sign up to get started!</h2>

            <div className="flex flex-col gap-6">
              <button
                type="button"
                onClick={handleSignUpClick}
                className="relative bg-green-900 inline-flex gap-3 w-full items-center justify-center rounded-md border border-transparent px-6 py-3 text-sm leading-6 font-bold text-neutral-100 shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-700  focus:ring-offset-2 hover:transition-all easy-in delay-80"
              >
                Express Sign up
                <img className="w-16xs absolute right-0 pr-4 py-3" src={trustlyLogo} alt="Trustly" />
              </button>
              <div className="flex items-center justify-between gap-2 text-neutral-400">
                <div className="w-full border-t border-neutral-200"></div>
                <div className="text-sm leading-6 text-neutral-600 font-bold">
                  or
                </div>
                <div className="w-full border-t border-neutral-200"></div>
              </div>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-green-800 bg-white px-4 py-3 text-sm leading-6 font-bold text-green-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 mb-3"
              >
                Sign up with email
              </button>
            </div>
          </div>
        </div>
      )}

      { isLightBoxRendered && <TrustlyFlow paymentType="Retrieval" /> }
    </div>
  );
}
