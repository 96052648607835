export function RadioInput({ id, selected, disabled }) {
  return (
    <>
      <input
        id={id}
        type="radio"
        readOnly
        {...(selected && { checked: true })}
        {...(disabled && { disabled: true })}
        className="hidden peer border-neutral-400 text-green-800 shadow-sm focus:border-green-300 focus:ring focus:ring-offset-0 focus:ring-green-800 focus:ring-opacity-20"
      />
      <label
        htmlFor={id}
        className="relative w-[18px] h-[18px] border border-neutral-400 peer-checked:border-0 peer-checked:bg-green-800 rounded-full after:content-[''] after:hidden peer-checked:after:block after:absolute after:w-[7px] after:h-[7px] after:bg-white after:rounded-full after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2"
      ></label>
    </>
  );
}
