import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import trustlyLogo from "../assets/trustly-logo.svg";

export function Header({ title }) {
  const navigate = useNavigate();

  return (
    <header className="bg-neutral-50 py-3 md:py-6 relative w-full text-center border-b border-neutral-200 md:border-none">
      <div className="container mx-auto px-6 lg:px-6">
        <img
          className={`w-20 ${title ? "hidden md:block" : ""}`}
          src={trustlyLogo}
          alt="Trustly logo"
        />
        <div className="md:hidden relative">
          {title && (
            <>
              <button onClick={() => navigate(-1)} className="absolute left-0 top-2/4 -translate-y-1/2">
                <ChevronLeftIcon
                  strokeWidth={3}
                  className="w-4 h-4 "
                />
              </button>
              <span className="justify-self-center leading-5 font-medium text-neutral-800">
                {title}
              </span>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
