import { RotatingLines } from "react-loader-spinner";
import { OverflowContainer } from "../OverflowContainer";

export function LoadingScreen({ title }) {
  return (
    <OverflowContainer>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="3"
        animationDuration="0.75"
        width="48"
        visible={true}
      />

      <h1 className="mt-4 leading-6 font-bold text-lg">
       { title }
      </h1>
    </OverflowContainer>
  );
}
