import { Avatar } from "../atoms/Avatar";
import { OverflowContainer } from "../OverflowContainer";

export function SplashScreen({ merchantName }) {
  return (
    <OverflowContainer className={'opacity-0 animate-slideIn transition'}>
      <Avatar letterIcon={merchantName[0]} />
      <h1 className="text-3xl leading-10 text-white font-bold mt-4">{merchantName}</h1>
    </OverflowContainer>
  );
}