export function Input({ id, name, type, value, onChangeMethod }) {
  return (
    <div className="block relative">
      <input
        type={type}
        className="mt-1 block py-3.5 px-4 w-full text-base rounded-md border border-neutral-400 shadow-sm peer"
        value={value}
        onChange={onChangeMethod}
        placeholder=" "
        id={id}
        name={id}
      />
      <label
        htmlFor={id}
        className="text-neutral-800 text-base absolute duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {name}
      </label>
    </div>
  );
}
