import { useContext } from "react";

import { Title } from "../atoms/Title";
import { DepositAmountSelector } from "../organisms/DepositAmountSelector";
import { PaymentMethods } from "../organisms/PaymentMethods";
import { LoadingScreen } from "../organisms/LoadingScreen";
import { SuccessfulScreen } from "../organisms/SuccessfulScreen";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { CanceledScreen } from "../organisms/CanceledScreen";

export function DepositFlow() {
  const { isCaptureProcessing, isCaptureCompleted, hasUserCanceled } =
    useContext(DeferredPaymentContext);
  return (
    <>
      {!isCaptureProcessing && isCaptureCompleted ? (
        <SuccessfulScreen title="Deposit completed" />
      ) : isCaptureProcessing ? (
        <LoadingScreen title="Processing your deposit" />
      ) : hasUserCanceled ? (
        <CanceledScreen title="Deposit canceled" />
      ) : (
        <>
          <Title title="Add Funds" />
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 gap-6">
            <PaymentMethods title={"Select a deposit method"} />

            <DepositAmountSelector />
          </div>
        </>
      )}
    </>
  );
}
