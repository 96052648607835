export function FlowBox({ children }) {
  return (
    <>  
      <div
        className="bg-white hover:bg-neutral-50 hover:transition-all easy-in delay-80 border border-neutral-300 p-4 rounded-md drop-shadow-sm"
      >
        {children}
      </div>
    </>
  );
}
