import { useState, useContext, useEffect } from "react";

import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";

import { DeferredPaymentContext } from "../context/DeferredPaymentContext";
import { DepositFlow } from "../components/templates/DepositFlow";
import { SplashScreen } from "../components/organisms/SplashScreen";

export function ReturningUser() {
  const { isCaptureProcessing, isCaptureCompleted, toggleHasUserLoggedIn, updatePaymentFlow } = useContext(
    DeferredPaymentContext
  );
  const [isLoadingDemo, setIsLoadingDemo] = useState(true);

  useEffect(() => {
    updatePaymentFlow("returning");
    toggleHasUserLoggedIn();
    setTimeout(() => setIsLoadingDemo(false), 2000);
  }, []);


  return (
    <div className={`w-full h-full ${isLoadingDemo ? "bg-green-900" : ""}`}>
        <Header
          title={
            isCaptureProcessing || isCaptureCompleted
              ? "Make a deposit"
              : "Add funds to digital wallet"
          }
        />

        {isLoadingDemo ? (
          <SplashScreen merchantName="Bet.on" />
        ) : (
          <div className="container px-6 lg:px-6 xl:px-0 pb-6 mx-auto mt-6">
            <Breadcrumbs title="Add funds to online wallet" />
            <DepositFlow />
          </div>
        )}

    </div>
  );
}
