import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export function PaymentStatusMessage({ variant, title }) {
  return (
    <>
      { variant === "success" && <CheckCircleIcon className="fill-green-800 w-16" /> }
      { variant === "error" && <XCircleIcon className="fill-red-700 w-16" /> }
      
      <h1 className="mt-4 mb-10 leading-8 font-bold text-xl">{title}</h1>

      <Link to={"/"} className={"w-full flex justify-center"}>
        <button
          type="button"
          className="w-full max-w-xs inline-flex justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        >
          Back to use cases
        </button>
      </Link>
    </>
  );
}
