import { Link } from "react-router-dom";

export function Breadcrumbs({ title }) {
  return (
    <nav className="bg-white md:mb-4 flex items-center flex-wrap">
      <ul className="items-center py-4 hidden md:block">
        <li className="inline-flex items-center">
          <Link
            to={"/"}
            className="text-neutral-600 hover:text-neutral-800"
          >
            Trustly Use Cases
          </Link>
          <span className="mx-4 h-auto text-neutral-400 font-medium">/</span>
        </li>
        <li className="inline-flex items-center">
          <span
            href="#"
            className="text-neutral-600 hover:text-neutral-800"
          >
            { title }
          </span>
        </li>
      </ul>
    </nav>
  );
}