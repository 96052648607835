import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Avatar } from "../components/atoms/Avatar";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { SplashScreen } from "../components/organisms/SplashScreen";

import graySneakers from "../assets/gray-sneakers.png";
import whiteSneakers from "../assets/white-sneakers.png";

export function Cart() {
  const [isLoadingDemo, setIsLoadingDemo] = useState(true);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    setTimeout(() => setIsLoadingDemo(false), 2000);
  }, []);

  return (
    <div className={`w-full h-full relative ${isLoadingDemo ? "bg-green-900" : ""}`}>
      <Header title={"Purchase sneakers"} />

      {isLoadingDemo ? (
        <SplashScreen merchantName="Shop.on" />
      ) : (
        <div className="container px-6 pb-6 mx-auto mt-6">
          <Breadcrumbs title="Purchase sneakers" />

          <div className="max-w-2xl">
            <Avatar letterIcon={"S"} />

            <div>
              <h1 className="text-2xl font-bold text-neutral-strong mt-8">
                Shopping cart
              </h1>

              <div>
                <div className="py-6 flex justify-between border-b border-neutral-300">
                  <img src={graySneakers} alt="" />
                  <div className="ml-3.5 mr-auto flex flex-col">
                    <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                      Prime Ultraspeed Stunt
                    </h2>
                    <span className="text-base leading-5 text-neutral-600">
                      Size 10.5
                    </span>
                    <span className="mt-auto text-base leading-5 text-neutral-600">
                      Qty 1
                    </span>
                  </div>
                  <span className="font-semibold text-neutral-strong text-base leading-5 self-end">
                    $90.00
                  </span>
                </div>

                <div className="py-6 flex justify-between">
                  <img src={whiteSneakers} alt="" />
                  <div className="ml-3.5 mr-auto flex flex-col">
                    <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                      Zen Archflow
                    </h2>
                    <span className="text-base leading-5 text-neutral-600">
                      Size 10.5
                    </span>
                    <span className="mt-auto text-base leading-5 text-neutral-600">
                      Qty 1
                    </span>
                  </div>
                  <span className="font-semibold text-neutral-strong text-base leading-5 self-end">
                    $100.00
                  </span>
                </div>
              </div>

              <div className="container mx-auto px-6 mb-10 absolute md:static md:px-0 left-0 right-0 bottom-0">
                <div className="flex justify-between py-5 border-t border-neutral-300">
                  <span className="text-base leading-5 font-semibold text-neutral-strong">
                    Subtotal
                  </span>
                  <span className="text-base leading-5 font-semibold text-neutral-strong">
                    $190.00
                  </span>
                </div>

                <button
                  onClick={() => navigate(state.urlPath)}
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
