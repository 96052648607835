export function DepositButton({ amount, rounded, selected, setDepositAmount }) {
  const isSelected = selected ? `border-green-800 bg-green-800 text-white` : `border-neutral-200 hover:bg-neutral-100 bg-white`;
  const isRounded = rounded ? `rounded-${rounded}-md` : ``;

  return (
    <button 
      onClick={() => setDepositAmount(amount)} 
      className={`${isRounded} ${isSelected} cursor-pointer border flex-auto div text-center easy-in delay-80 shadow-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-800 focus:relative focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80`}
    >
      ${ amount }
    </button>
  );
}