import { useState } from "react";
import { createContext } from "react";
import { Outlet } from "react-router-dom";

export const RetrievalPaymentContext = createContext();

export function RetrievalPaymentProvider({ children }) {
  const [paymentDetails, setPaymentDetails] = useState([]);

  function updatePaymentDetails(updatedPaymentDetails) {
    const newPaymentDetails = [...paymentDetails, updatedPaymentDetails]
    setPaymentDetails(newPaymentDetails);

    return localStorage.setItem(
      "Trustly.paymentRetrievalDetails",
      JSON.stringify(newPaymentDetails)
    );
  }

  return (
    <RetrievalPaymentContext.Provider
      value={{
        paymentDetails,
        updatePaymentDetails
      }}
    >
      {children}
      <Outlet />
    </RetrievalPaymentContext.Provider>
  );
}
