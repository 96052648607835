import { useContext, useState } from "react";

import { DepositButton } from "../atoms/DepositButton";
import { Subtitle } from "../atoms/Subtitle";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";

import { captureTransaction } from "../../api";

export function DepositAmountSelector() {
  const [depositAmount, setDepositAmount] = useState("");
  const { hasUserLoggedIn, paymentDetails, paymentFlow, toggleIsCaptureProcessing, toggleIsCaptureCompleted } = useContext(DeferredPaymentContext);

  const depositValues = [
    { amount: "10", rounded: "l"},
    { amount: "25" },
    { amount: "100" },
    { amount: "250", rounded: "r"},
  ];

  function handleDepositAmountChange(depositAmount) {
    setDepositAmount(depositAmount);
  }

  async function handleCapture() {
    toggleIsCaptureProcessing();
    
    if (!paymentDetails.dummyAccount) {
      await captureTransaction(
        paymentDetails.transactionId,
        paymentDetails.merchantReference,
        depositAmount
      );
    }

    setTimeout(() => {
      toggleIsCaptureProcessing();
      toggleIsCaptureCompleted();
      paymentFlow === "returning" && localStorage.removeItem("Trustly.paymentDetails")
    }, paymentDetails.dummyAccount && 3000)

  }

  return (
    <div>
      <Subtitle title="Choose a deposit amount" />

      <div className="flex w-full divide-x divide-x-reverse mb-6">
        { depositValues.map(deposit => (
          <DepositButton
            key={deposit.amount}
            selected={depositAmount === deposit.amount}
            setDepositAmount={handleDepositAmountChange}
            {...deposit}
          />
        ))}
      </div>

      <button
        type="button"
        onClick={handleCapture}
        disabled={!hasUserLoggedIn || !depositAmount}
        className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
      >
        Make deposit
      </button>
    </div>
  );
}
