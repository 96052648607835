import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { CheckoutFlow } from "../components/templates/CheckoutFlow";

import graySneakers from "../assets/gray-sneakers.png";
import whiteSneakers from "../assets/white-sneakers.png";
import { Title } from "../components/atoms/Title";
import { Avatar } from "../components/atoms/Avatar";
import { useContext } from "react";
import { DeferredPaymentContext } from "../context/DeferredPaymentContext";
import { captureTransaction } from "../api";
import { SuccessfulScreen } from "../components/organisms/SuccessfulScreen";
import { LoadingScreen } from "../components/organisms/LoadingScreen";
import { CanceledScreen } from "../components/organisms/CanceledScreen";

export function NewUserCheckout() {
  const { 
    hasUserLoggedIn, 
    toggleIsCaptureProcessing, 
    toggleIsCaptureCompleted, 
    paymentDetails, 
    paymentFlow,
    isCaptureProcessing,
    isCaptureCompleted,
    hasUserCanceled 
  } = useContext(DeferredPaymentContext);

  async function handleCapture() {
    toggleIsCaptureProcessing();
    
    if (!paymentDetails.dummyAccount) {
      await captureTransaction(
        paymentDetails.transactionId,
        paymentDetails.merchantReference,
        "190.00"
      );
    }

    setTimeout(() => {
      toggleIsCaptureProcessing();
      toggleIsCaptureCompleted();
      paymentFlow === "returning" && localStorage.removeItem("Trustly.paymentDetails")
    }, paymentDetails.dummyAccount && 3000)

  }

  return (
    <div className={`w-full h-full`}>
      <Header title="Purchase sneakers" />

      <div className="container px-6 pb-6 mx-auto mt-6">
        <Breadcrumbs title="Purchase sneakers" />

        {!isCaptureProcessing && isCaptureCompleted ? (
        <SuccessfulScreen title="Purchase completed" />
      ) : isCaptureProcessing ? (
        <LoadingScreen title="Processing your payment" />
      ) : hasUserCanceled ? (
        <CanceledScreen title="Purchase canceled" />
      ) : (
        <>
          <div className="mb-8">
            <Avatar letterIcon={"S"} />
          </div>
          <Title title="Checkout" />
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 gap-6">
            <div className="mb-4 lg:mt-12 xl:col-span-1 lg:order-2">
              <div className="relative pb-6 pt-3 md:pt-0 flex justify-between border-b border-neutral-300">
                <img src={graySneakers} className="w-[72px] h-[72px]" alt="Gray sneakers" />
                <div className="ml-3.5 mr-auto flex flex-col">
                  <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                    Prime Ultraspeed Stunt
                  </h2>
                  <span className="text-base leading-5 text-neutral-600">
                    Size 10.5
                  </span>
                  <span className="mt-auto text-base leading-5 text-neutral-600">
                    Qty 1
                  </span>
                </div>
                <span className="absolute right-0 font-semibold text-neutral-strong text-base leading-5 self-end">
                  $90.00
                </span>
              </div>

              <div className="py-6 flex justify-between border-b border-neutral-300">
                <img src={whiteSneakers} className="w-[72px]" alt="White sneakers" />
                <div className="ml-3.5 mr-auto flex flex-col">
                  <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                    Zen Archflow
                  </h2>
                  <span className="text-base leading-5 text-neutral-600">
                    Size 10.5
                  </span>
                  <span className="mt-auto text-base leading-5 text-neutral-600">
                    Qty 1
                  </span>
                </div>
                <span className="font-semibold text-neutral-strong text-base leading-5 self-end">
                  $100.00
                </span>
              </div>
              <div className="hidden mt-6 lg:block">
              <div className="border-b border-neutral-300 py-4 text-base leading-5 text-neutral-600">
                <div className="flex justify-between mb-2">
                  <span>Subtotal</span>
                  <span>$90.00</span>
                </div>
                <div className="flex justify-between">
                  <span>Shipping</span>
                  <span>Free</span>
                </div>
              </div>
              <div className="py-5 flex justify-between text-base leading-5 font-semibold text-neutral-strong">
                <span>Total</span>
                <span>$190.00</span>
              </div>
              <button
                type="button"
                onClick={handleCapture}
                disabled={!hasUserLoggedIn}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
              >
                Place order
              </button>
              </div>
            </div>
            <CheckoutFlow />
            <div className="mt-4 lg:hidden">
              <div className="border-y border-neutral-300 py-4 text-base leading-5 text-neutral-600">
                <div className="flex justify-between mb-2">
                  <span>Subtotal</span>
                  <span>$90.00</span>
                </div>
                <div className="flex justify-between">
                  <span>Shipping</span>
                  <span>Free</span>
                </div>
              </div>
              <div className="py-5 flex justify-between text-base leading-5 font-semibold text-neutral-strong">
                <span>Total</span>
                <span>$190.00</span>
              </div>
              <button
                type="button"
                onClick={handleCapture}
                disabled={!hasUserLoggedIn}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
              >
                Place order
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </div>
  );
}
